<!--中标业绩 xwh 20210913-->
<template>
    <div id="bidding-performance">
        <div class="search-box">
            <div class="module-title">中标业绩</div>
            <el-form :inline="true" :model="params" class="demo-form-inline" size="mini">
                <el-form-item label="项目名称">
                    <el-input v-model="params.projectName" placeholder="请输入项目名称"></el-input>
                </el-form-item>
                <el-form-item label="中标时间">
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        align="right"
                        unlink-panels
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="getTableList(params)">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table ref="table" :data="tableData" border stripe height="400" :summary-method="getSummaries" :header-cell-style="{background:'#F6F6F6',color:'#333333'}"
                  v-loading="loading">
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column prop="projectName" label="项目名称" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="amount" label="中标金额(万元)" align="right">
                <template slot-scope="scope">
                    <span>{{ utils.dataFormat(scope.row.amount) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="bidDate" label="中标时间"></el-table-column>
            <el-table-column prop="manager" label="项目经理"></el-table-column>
            <el-table-column label="项目属地">
                <template slot-scope="scope">
                    <span>{{ scope.row.province }}{{ scope.row.city }}{{ scope.row.country }}</span>
                </template>
            </el-table-column>
            <el-table-column label="招标人及联系方式">
                <template slot-scope="scope">
                    <span>{{ scope.row.bidCompany }}{{ scope.row.contact }}</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="page-box">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                background
                layout="prev,pager,next,total,sizes,jumper"
                :page-sizes="[50, 100]"
                :total=total
                :current-page=params.pageNum>
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {getStore} from "@/util/store";
export default {
    data() {
        return {
            params: {
                company: '',
                projectName: '',
                startDate: '',
                endDate: '',
                pageNum: 1,
                pageSize: 50
            },
            tableData: [],
            loading: true,
            total: 0,
            dataTotal: ['合计', '', 22222, '', '', ''],
            dateRange:[],
        }
    },
    created() {
        this.params.company = getStore('thisProject').taxpayerName;
        this.getTableList(this.params);
    },
    mounted() {},
    methods: {
        //查询talbe数据
        getTableList(parm) {
            this.params.startDate = this.dateRange[0];
            this.params.endDate = this.dateRange[1];
            this.axios({
                method: "post", url: '/dms/pageBidInfo',
                data: parm,
            }).then((res) => {
                //this.loading = false;
                //console.log(res.data.data)
                this.tableData = res.data.data.records;
                this.total = res.data.data.total;
                //this.total = 200
                this.loading = false;
            }).catch((error) => {
                console.log(error)
            })
        },
        //table合计
        getSummaries() {
            //解决合计被遮挡问题
            this.$nextTick(() => {
                this.$refs.table.doLayout();
            });
            return this.dataTotal;
        },
        //更改每页显示条数
        handleSizeChange(val) {
            this.params.pageSize = val;
            this.params.pageNum = 1;
            this.getTableList(this.params);
        },
        //更改页码
        handleCurrentChange(val) {
            this.params.pageNum = val;
            this.getTableList(this.params);
        },
    }
}
</script>

<style scoped lang="less">
    .search-box {
        display: flex;
        justify-content: space-between;
    }
    .module-title {
        font-size: 16px;
        font-weight: bold;
    }
</style>