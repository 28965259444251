<template>
    <div class="base-container" id="scroll">
        <div class="main-wrapper">
            <div class="banner">
                <div class="left">
                    <div class="logo">
                        <div class="text">企业<br/>LOGO</div>
                    </div>
                    <div class="line"></div>
                </div>
                <div class="right">
                    <div class="info">
                        <div class="company">
                            <div class="name" v-text="thisProjectInfo.taxpayerName"></div>
                            <!-- <div class="status">
                                <div class="dot"></div>
                                <div class="text">在业</div>
                            </div> -->
                        </div>
                        <!--                        <div class="tags">
                                                    <el-tag class="" color="#EDF4FF">高新技术企业</el-tag>
                                                    <el-tag class="" color="#EDF4FF">中小型企业</el-tag>
                                                    <el-tag class="" color="#FFF5ED">自身风险 52条</el-tag>
                                                    <el-tag class="" color="#FFF5ED">司法案件 36个</el-tag>
                                                    <el-tag class="" color="#FFEBEB">失信企业</el-tag>
                                                </div>-->
                        <div class="info1">
                            <div class="info1-1">
                                <div>法人代表：</div>
                                <div v-text="companyBaseInfo.legalPerson"></div>
                            </div>
                            <div class="info1-1">
                                <div>注册资本：</div>
                                <div v-text="companyBaseInfo.registeringCapital"></div>
                            </div>
                            <div class="info1-1">
                                <div>成立日期：</div>
                                <div>{{ companyBaseInfo.foundationDate }}</div>
                            </div>
                            <div class="info1-1">
                                <div>登记机关：</div>
                                <div v-text="companyBaseInfo.registrationOrgan"></div>
                                <!--                            <div class="dot" v-popover:popover></div>-->
                            </div>
                        </div>
                        <div class="info2">
                            <div class="info2-1">
                                <div>电话：</div>
                                <div v-text="companyBaseInfo.contactNumber"></div>
                            </div>
                            <div class="info2-1">
                                <div>邮箱：</div>
                                <div v-text="companyBaseInfo.email"></div>
                            </div>
                            <div class="info2-1">
                                <div>官网：</div>
                                <div v-text="companyBaseInfo.offcialWebsite"></div>
                            </div>
                        </div>
                        <div class="info3">
                            <i class="el-icon-map-location"></i>
                            <div class="location" v-text="companyBaseInfo.businessAddress"></div>
                            <div class="info2-1">
                                <div>曾用名：</div>
                                <div v-text="companyBaseInfo.formerName || '-'"></div>
                            </div>
                        </div>
                        <el-popover ref="popover" popper-class="other-data-sources" placement="bottom" title="其他数据来源"
                                    width="200" trigger="click">
                            <div class="datatype enterprise-declaration">
                                <div class=""
                                     style="margin-bottom: 5px;font-size: 14px;margin-left: 14px;display: inline-block; color: #969799;">
                                    企业申报：
                                </div>
                                <div class=""
                                     style="margin-bottom: 5px;font-size: 14px;margin-left: 14px;display: inline-block">
                                    贰级
                                </div>
                            </div>
                            <div class="datatype golden-three-system">
                                <div class=""
                                     style="margin-bottom: 5px;font-size: 14px;margin-left: 14px;display: inline-block; color: #969799;">
                                    金三系统：
                                </div>
                                <div class=""
                                     style="margin-bottom: 5px;font-size: 14px;margin-left: 14px;display: inline-block">
                                    壹级
                                </div>
                            </div>
                            <div class="datatype enterprise-check">
                                <div class=""
                                     style="font-size: 14px;margin-left: 14px;display: inline-block; color: #969799;">
                                    企查查：&emsp;
                                </div>
                                <div class="" style="font-size: 14px;margin-left: 14px;display: inline-block">叁级</div>
                            </div>
                            <div class="datatype other-feedback"
                                 style="color:#185BCE; font-weight: bold; cursor:pointer ;margin-top: 16px;margin-left: 14px">
                                错误反馈
                            </div>
                        </el-popover>
                    </div>
                </div>
            </div>
            <div class="header" :class="{'fixed':isFixed}">
                <div @click="goId('#jyfw')" class="nav" :class="{'active':navName == '#jyfw'}" v-if="utils.isDisplay('constructorCompanyDetail:view')">经营范围</div>
                <div @click="goId('#lsjs')" class="nav" :class="{'active':navName == '#lsjs'}" v-if="utils.isDisplay('historicalProject:view')">历史建设信息</div>
                <div @click="goId('#tdjy')" class="nav" :class="{'active':navName == '#tdjy'}" v-if="utils.isDisplay('landDetail:view')">土地交易信息</div>
                <div @click="goId('#xwyq')" class="nav" :class="{'active':navName == '#xwyq'}" v-if="utils.isDisplay('news:view')">新闻舆情</div>
                <div @click="goId('#tzgx')" class="nav" :class="{'active':navName == '#tzgx'}" v-if="utils.isDisplay('investRelation:view')">投资关系分析</div>
                <div @click="goId('#dwtz')" class="nav" :class="{'active':navName == '#dwtz'}" v-if="utils.isDisplay('investOutgoing:view')">对外投资分析</div>
                <div @click="goId('#glgx')" class="nav" :class="{'active':navName == '#glgx'}" v-if="utils.isDisplay('relatedRelation:view')">关联施工单位分析</div>
            </div>
            <div class="item-box" id="jyfw" v-if="utils.isDisplay('constructorCompanyDetail:view')">
                <div class="title">经营范围</div>
                <div class="gap-line"></div>
                <div class="describe" v-text="companyBaseInfo.businessScope"></div>
            </div>
            <div class="item-box" id="lsjs" v-if="utils.isDisplay('historicalProject:view')">
                <historyProject></historyProject>
            </div>
            <div class="item-box" id="tdjy" v-if="utils.isDisplay('landDetail:view')">
                <land-transaction></land-transaction>
            </div>
            <div class="item-box" id="xwyq" v-if="utils.isDisplay('news:view')">
                <newsOpinion></newsOpinion>
            </div>
            <div class="item-box" id="tzgx" v-if="utils.isDisplay('investRelation:view')">
                <div class="title">投资关系分析</div>
                <div class="gap-line"></div>
                <div class="item-content">
                    <el-tabs v-if="intoInvestFlag" v-model="intoInvestActive" type="card" @tab-click="handleClick">
                        <el-tab-pane label="图表" name="chart">
                            <into-invest-chart v-if="intoInvestFlag" :chartData="data1" ></into-invest-chart>
                        </el-tab-pane>
                        <el-tab-pane label="表格" name="table">
                            <into-invest-table :tableData="table1"></into-invest-table>
                        </el-tab-pane>
                    </el-tabs>
                    <div class="noData" v-else></div>
                </div>
            </div>
            <div class="item-box" id="dwtz" v-if="utils.isDisplay('investOutgoing:view')">
                <div class="title">对外投资分析
                    <!--<div class="text">投资关系分析 <i></i></div>-->
                </div>
                <div class="gap-line"></div>
                <div class="item-content">
                    <el-tabs v-if="outerInvestFlag" v-model="outerInvestActive" type="card" @tab-click="outHandleClick">
                        <el-tab-pane label="图表" name="chart">
                            <outer-invest-chart v-if="outerInvestFlag" :chartdata="data2"></outer-invest-chart>
                        </el-tab-pane>
                        <el-tab-pane label="表格" name="table">
                            <outer-invest-table :tabledata="table2"></outer-invest-table>
                        </el-tab-pane>
                    </el-tabs>
                    <div class="noData" v-else></div>
                </div>
            </div>
            <div class="item-box" id="glgx" v-if="utils.isDisplay('relatedRelation:view')">
                <associatedConstruction></associatedConstruction>
            </div>
            <!-- <div class="item-box">
                <div class="title">合作关系分析</div>
                <div class="gap-line"></div>
                <div class="item-content">
                    <el-tabs v-if="cooperateFlag" v-model="cooperateActive" type="card">
                        <el-tab-pane label="图表" name="chart">
                            <cooperation-chart  v-if="cooperateFlag" :chartdata="data3"></cooperation-chart>
                        </el-tab-pane>
                        <el-tab-pane ref="table" label="表格" name="table">
                            <cooperation-table :tabledata="table3"></cooperation-table>
                        </el-tab-pane>
                    </el-tabs>
                    <div class="noData" v-else></div>
                </div>
            </div> -->
        </div>
    </div>

</template>

<script>
import biddingPerformance from "@/views/analysisDetails/biddingPerformance";
import landTransaction from "@/views/analysisDetails/landTransaction/landTransaction";
import intoInvestChart from "@/views/analysisDetails/intoInvest/intoInvestChart";
import intoInvestTable from "@/views/analysisDetails/intoInvest/intoInvestTable";
import outerInvestChart from "@/views/analysisDetails/outerInvest/outerInvestChart";
import outerInvestTable from "@/views/analysisDetails/outerInvest/outerInvestTable";
import cooperationChart from "@/views/analysisDetails/cooperation/cooperationChart";
import cooperationTable from "@/views/analysisDetails/cooperation/cooperationTable";
import newsOpinion from "@/views/analysisDetails/newsOpinion.vue";

import historyProject from "@/views/historyProject/historyProject";
import associatedConstruction from "@/views/associatedConstruction/associatedConstruction.vue";
import {getStore,setStore,getLocalStore} from "@/util/store";
import {mapMutations} from "vuex"; //分析页通用table表格展示页
let scrollDom;
export default {
    components: {
        biddingPerformance,
        landTransaction,
        intoInvestChart,
        intoInvestTable,
        outerInvestChart,
        outerInvestTable,
        cooperationChart,
        cooperationTable,
        historyProject,
        associatedConstruction,
        newsOpinion
    },
    data() {
        return {
            companyBaseInfo: {},
            thisProjectInfo: '',
            intoInvestFlag:false,
            intoInvestActive:'chart',
            outerInvestFlag: false,
            outerInvestActive: 'chart',
            cooperateFlag: false,
            cooperateActive: "chart",
            data1: {},//投资关系分析模块 关系图 源数据
            table1:[],//投资关系分析模块 table表 源数据
            data2: {},//对外投资关系分析模块 关系图 源数据
            table2: [],//对外投资关系分析模块 table表 源数据
            // data3: {},//合作关系分析模块 echarts图 源数据
            // table3: [],//合作关系分析模块 table表 源数据
            navName: '#jyfw',
            isFixed: false,
        }
    },
    created: function () {
        this.getReport(0,'','');
        this.thisProjectInfo = getStore('thisProject');
        //获取建设方基本信息
        if(this.thisProjectInfo.taxpayerCode){
            this.loadCompanyBaseInfo(this.thisProjectInfo.taxpayerCode.trim())
        }
        //投资关系
        this.loadIntoInvest(this.thisProjectInfo.taxpayerName);
        //对外投资
        this.loadOuterInvest(this.thisProjectInfo.taxpayerName);
        //合作暂时去掉
        // this.loadCooprelation(this.thisProjectInfo.taxpayerName)
    },
    mounted: function () {
        if (window.history && window.history.pushState) {
            history.pushState(null, null, document.URL);
            // 给 popstate 绑定一个方法 监听页面刷新
            window.addEventListener('popstate', this.back, false);
        }
        scrollDom = document.getElementById('scroll')
        scrollDom.addEventListener('scroll',this.handleScroll)
    },
    destroyed() {
        this.getReport(1,'','');
        window.removeEventListener('popstate', this.back, false);
        scrollDom.removeEventListener("scroll",this.handleScroll);
    },
    methods: {
        ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
         goId(id){
            if(id=='#jyfw'){
                this.getReport(2,'nav','natureBusiness')
            }else if(id=='#lsjs'){
                this.getReport(2,'nav','history')
            }else if(id=='#tdjy'){
                this.getReport(2,'nav','landTransaction')
            }else if(id=='#tzgx'){
                this.getReport(2,'nav','investment')
            }else if(id=='#xwyq'){
                //新闻舆情
                this.getReport(2,'nav','newsOpinion')
            }else if(id=='#dwtz'){
                this.getReport(2,'nav','outInvestment')
            }else if(id=='#glgx'){
                this.getReport(2,'nav','associatedConstruction')
            }
            this.navName = id;
             document.querySelector(id).scrollIntoView({block: "center", inline: "nearest", behavior: 'smooth'});
        },
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                data: {
                    pageName:'building',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        handleScroll(){
            let mainHeight = scrollDom.scrollTop;
            if(mainHeight>218){
                this.isFixed = true;
            }else{
                this.isFixed = false;
            }
            if(document.getElementById('jyfw')){
                let jyfwScrollTop = document.getElementById('jyfw').offsetTop;
                if(jyfwScrollTop-mainHeight<300){
                    this.navName = '#jyfw'
                }
            }
            if(document.getElementById('lsjs')){
                let gchtScrollTop = document.getElementById('lsjs').offsetTop;
                if(gchtScrollTop-mainHeight<300){
                    this.navName = '#lsjs'
                }
            }
            if(document.getElementById('tdjy')){
                let qyzzScrollTop = document.getElementById('tdjy').offsetTop;
                if(qyzzScrollTop-mainHeight<300){
                    this.navName = '#tdjy'
                }
            }
            if(document.getElementById('tzgx')){
                let lsyjScrollTop = document.getElementById('tzgx').offsetTop;
                if(lsyjScrollTop-mainHeight<300){
                    this.navName = '#tzgx'
                }
            }
            if(document.getElementById('xwyq')){
                let xwyqScrollTop = document.getElementById('xwyq').offsetTop;
                if(xwyqScrollTop-mainHeight<300){
                    this.navName = '#xwyq'
                }
            }
            if(document.getElementById('dwtz')){
                let glfxScrollTop = document.getElementById('dwtz').offsetTop;
                if(glfxScrollTop-mainHeight<300){
                    this.navName = '#dwtz'
                }
            }
            if(document.getElementById('glgx')){
                let glgxScrollTop = document.getElementById('glgx').offsetTop;
                if(glgxScrollTop-mainHeight<300){
                    this.navName = '#glgx'
                }
            }
        },
        back() {
            this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
            this.reducePath();//删除导航最后一个路由
            var currentMenu = getStore("currentMenu");
            this.$router.push({path: currentMenu});
        },
        handleClick() {
            this.getReport(2,'investment',this.intoInvestActive)
        },
        outHandleClick(){
            this.getReport(2,'outInvestment',this.outerInvestActive)
        },
        loadCompanyBaseInfo(taxpayerCode) {
            this.axios.request({
                loading: true,
                method: "post",
                url: 'dms/getBusinessBaseInfo',
                params: {
                    taxpayerCode: taxpayerCode   //避免前后有制表符或者空字符串
                }
            }).then((res) => {
                if (res.data.status == "failed") {
                    if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                       
                    }else{
                        this.$message.error(res.data.msg);
                    }
                } else if (res.data.status == "success") {
                    if (res.data.data) {
                        this.companyBaseInfo = res.data.data;
                    } else {
                        if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                       
                        }else{
                            this.$message.error(res.data.msg);
                        }
                    }
                }
            }).catch((error) => {
                console.log(error);
            })
        },
        loadIntoInvest(companyName) {
            this.axios({
                method: "get", url: '/companyRelation/investInComing',
                params: {
                    companyName: companyName
                },
            }).then((res) => {
                if (res.data.status == "failed") {
                    if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                       
                    }else{
                        this.$message.error('投资关系：' + res.data.msg)
                    }
                } else {
                    if (res.data.data.overview) {
                        this.data1 = res.data.data.overview
                        this.table1 = res.data.data.detail
                        this.intoInvestFlag = true;
                    }
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        loadOuterInvest(companyName){
            this.axios({
                method: "get", url: '/companyRelation/investOutgoing',
                params: {
                    companyName: companyName
                },
            }).then((res) => {
                if (res.data.status == "failed") {
                    if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                       
                    }else{
                        this.$message.error('对外投资：' + res.data.msg)
                    }
                } else {
                    if (res.data.data.overview) {
                        this.data2 = res.data.data.overview
                        this.table2 = res.data.data.detail
                        this.outerInvestFlag = true;
                    }
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        // loadCooprelation(companyName) {
        //     this.axios.request({
        //         loading: true,
        //         method: "get",
        //         url: '/companyRelation/cooperation',
        //         params: {
        //             companyName: companyName,
        //         },
        //     }).then((res) => {
        //         if (res.data.status == "failed") {
        //             this.$message.error('合作关系：' + res.data.msg)
        //         } else {
        //             if (res.data.data.overview) {
        //                 this.data3 = res.data.data.overview
        //                 this.table3 = res.data.data.detail
        //                 this.cooperateFlag = true;
        //             }
        //         }
        //     })
        // },

    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
    width: 0;
}

.banner {
    display: flex;
    width: 100%;
    background: #FFFFFF;
    border-radius: 2px;

    .left {
        position: relative;
        width: 158px;
        height: 218px;

        .logo {
            position: absolute;
            width: 78px;
            height: 78px;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            color: #000C17;
            font-size: 16px;
            background-color: #F2F3F5;
            border-radius: 2px;
            //opacity: 0.1;
            .text {
                position: absolute;
                width: 36px;
                height: 36px;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }

        .line {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 178px;
            background-color: #F2F3F5;
        }
    }


    .right {
        position: relative;
        width: calc(100% - 160px);
        padding: 24px 40px;

        .info {
            width: 100%;
            height: auto;

            .company {
                display: flex;
                margin-bottom: 8px;
                width: 100%;
                height: 26px;

                .name {
                    color: #323233;
                    font-size: 18px;
                    height: 26px;
                    line-height: 26px;
                }

                .status {
                    display: flex;
                    align-items: center;
                    margin-left: 16px;

                    .dot {
                        width: 10px;
                        height: 10px;
                        margin-right: 6px;
                        background: #2DA641;
                        border-radius: 10px;
                        border: 2px solid #D8F4DC;
                    }

                    .text {
                        width: 28px;
                        height: 20px;
                        line-height: 20px;
                        font-weight: 400;
                        color: #2DA641;
                    }


                }
            }

            .tags {
                .el-tag {
                    margin-right: 8px;
                    border: none;
                    height: 20px;
                    line-height: 20px;
                    width: auto;
                }

                :nth-child(1), :nth-child(2) {
                    color: #185BCE
                }

                :nth-child(3), :nth-child(4) {
                    color: #ED6A0C
                }

                :nth-child(5) {
                    color: #D40000
                }
            }

            .info1 {
                display: flex;
                margin-top: 25px;
                width: 100%;
                height: 20px;
                line-height: 20px;
                font-size: 14px;

                .info1-1 {
                    display: inline-flex;
                    margin-right: 24px;

                    :nth-child(1) {
                        color: #969799;
                    }

                    :nth-child(2) {
                        color: #646566;
                    }

                }

                .dot {
                    display: inline-flex;
                    justify-content: space-around;
                    margin: auto 5px;
                    width: 8px;
                    height: 8px;
                    background: #D40000;
                    border-radius: 8px;
                    cursor: pointer;
                }
            }

            .info2 {
                margin-top: 16px;
                display: flex;
                height: 20px;
                line-height: 20px;
                font-size: 14px;

                div {
                    display: inline-flex;

                    :nth-child(1) {
                        color: #969799;
                    }

                    :nth-child(2) {
                        color: #646566;
                        margin-right: 24px;
                    }

                }
            }

            .info3 {
                margin-top: 16px;
                display: flex;
                height: 20px;
                line-height: 20px;
                font-size: 14px;

                i {
                    margin-right: 8px;
                    padding-top: 2.5px;
                }

                .location {
                    color: #646566;
                }
                .info2-1 {
                    display: inline-flex;
                    margin-left: 24px;
                    :nth-child(1) {
                        color: #969799;
                    }

                    :nth-child(2) {
                        color: #646566;
                        margin-right: 24px;
                    }

                }
            }

            /deep/ .other-data-sources {
                .datatype {
                    display: inline-block;

                    :nth-child(1) {
                        display: inline-block;
                        width: 70px;
                        height: 20px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #969799;
                        line-height: 20px;
                    }

                    :nth-child(2) {
                        display: inline-block;
                        width: 28px;
                        height: 20px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #646566;
                        line-height: 20px;
                    }
                }

                /deep/ .enterprise-check {
                    :nth-child(1) {
                        width: 56px;
                        height: 20px;
                        margin-left: 14px;
                    }

                }

                /deep/ .other-feedback {
                    margin-top: 16px;
                    width: 56px;
                    height: 20px;
                    font-size: 14px;
                    font-weight: bold;
                    color: #185BCE;
                    line-height: 20px;
                    cursor: pointer;
                }
            }
        }
    }
}
.header{
    width: 1200px;
    height: 57px;
    overflow: hidden;
    background: #ffffff;
    margin-bottom: 24px;
    z-index: 9;
    display: flex;
    .nav{
        float: left;
        border: 1px solid #EBEDF0;
        box-sizing: border-box;
        text-align: center;
        line-height: 55px;
        cursor: pointer;
        border-right: none;
        flex: 1;
    }
    .nav:last-child{
        border-right: 1px solid #EBEDF0;
    }
    .nav.active{
        color: #185BCE;
    }
}
.fixed{
    position: fixed;
    top: 160px;
}

.item-box {
    background: #FFFFFF;
    padding: 15px;
    box-sizing: border-box;
    margin-top: 15px;

    .title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 15px;

        i {
            display: inline-block;
            position: absolute;
            margin: 3px 10px;
            cursor: pointer;
            width: 16px;
            height: 16px;
            background: url("../../assets/img/question.png") center no-repeat;
        }
    }

    .gap-line {
        height: 1px;
        background: #eceaea;
        margin-bottom: 15px;
    }
}

.describe {
    font-size: 14px;
    line-height: 1.5;
    padding: 0 15px;
}

/deep/ .el-tabs--card > .el-tabs__header {
    border-bottom: none;
}

/deep/ .el-tabs__item.is-active {
    color: #FFFFFF;
    background-color: #185BCE;
}

/deep/ .el-tabs__content {
    width: 100%;
    position: relative;
}

/deep/ .el-tabs__item {
    width: 44px;
    height: 26px;
    line-height: 26px;
    padding: 0 7px !important;
    background: rgba(50, 50, 51, 0.06);
    border-radius: 2px 0 0 2px;
    border: 1px solid rgba(50, 50, 51, 0.06);
}
/deep/.el-tabs__nav{
    border: none;
    border-radius:2px !important;
    overflow: hidden;
}
/deep/.el-tabs__item{
    border: none !important;
}

</style>