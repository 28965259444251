<template>
    <div>
        <el-table
            border
            :data="tabledata"
            stripe
            :header-cell-style="{background:'#F6F6F6',color:'#333333'}"
            height="450">
            <el-table-column
                type="index"
                label="序号"
                align="center"
                width="50">
            </el-table-column>
            <el-table-column
                prop="companyName"
                label="投资单位/人">
            </el-table-column>
            <el-table-column
                prop="otherCompanyName"
                label="被投单位">
            </el-table-column>
            <el-table-column
                prop="relationshipType"
                label="类型"
                width="100">
            </el-table-column>
            <el-table-column
                prop="description"
                label="详情">
            </el-table-column>
            <el-table-column
                prop="percent"
                label="投资比例"
                width="100">
            </el-table-column>

        </el-table>
    </div>

</template>

<script>
export default {
    props: {
        tabledata: Array,
    },
}
</script>

<style scoped>

</style>