<template>
    <div id="outer-invest">
        <img src="@/assets/img/full-screen.png" class="btn-full-screen" @click="chartFullScreen()">
        <el-dialog title="对外投资关系" :visible.sync='showFullScreen' fullscreen>
            <div id="out-full" v-if="showFullScreen" ></div>
        </el-dialog>
    </div>
</template>

<script>
//引入antv g6
import G6 from '@antv/g6';
export default {
    props: {
        chartdata: {},  //父组件传递的关系分析图数据
    },
    data() {
        return {
            relationData: {},
            showFullScreen: false,
            chartType: 'LR'
        }
    },
    mounted() {
        this.relationData = JSON.parse(JSON.stringify(this.chartdata))
        //判断子集数量决定布局方式
        if(this.relationData.children.length>3){
            this.chartType = 'H'
        }
        this.chartRender(this.relationData,'outer-invest');
    },
    methods: {
        chartRender(data,id) {
            //获取元素宽 高
            var dom = document.getElementById(id);
            var width = dom.offsetWidth;
            var height = dom.offsetHeight;

            //场景图谱实例
            const treeGraph = new G6.TreeGraph({
                container: id,
                width: width,
                height: height,
                animate: true, // Boolean，切换布局时是否使用动画过度，默认为 false
                animateCfg: {
                    duration: 250, // Number，一次动画的时长
                    easing: 'linearEasing', // String，动画函数
                },
                autoPaint: false,
                //交互模式
                modes: {
                    default: [
                        {
                            type: 'drag-canvas',
                            allowDragOnItem: true,//设置节点、边等均可响应拖拽
                        },
                        // {
                        //     type: 'zoom-canvas',
                        //     sensitivity: 1
                        // },
                        'collapse-expand',
                    ],
                },
                //定义布局
                layout: {
                    type: 'mindmap',//类型：脑图
                    direction: this.chartType,
                    getHeight: () => {
                        return 35;
                    },
                    getWidth: () => {
                        return 150;
                    },
                    getVGap: () => { //水平间距
                        return 10;
                    },
                    getHGap: () => { //垂直间距
                        return 50;
                    },
                },
                //默认节点样式
                defaultNode: {
                    type: 'rect', // 节点类型 矩形
                    draggable: true,
                    size: [250, 35],
                    anchorPoints: [
                        [0, 0.5],
                        [1, 0.5],
                    ],
                    label: "",
                    labelCfg: {
                        position: 'center',
                        //offset:10,
                        style: {
                            fill: '#333',
                        }
                    },
                },
                //默认线的样式
                defaultEdge: {
                    type: 'cubic-horizontal',
                    //type: 'polyline', // 节点类型 矩形
                    //type:'flowLine',
                    style: {
                        stroke: '#8dd3d3',
                        //offset: 15,
                    }
                }
            });
            //节点的文本和样式根据级别进行重新赋值
            treeGraph.node((node) => {
                //console.log(node)
                var nodeCfg = {
                    type: 'rect',
                    size: '',
                    style: {
                        fill: '#ffffff',
                    },
                    label: node.name,
                    labelCfg: {
                        style: {
                            fill: '#333',
                        }
                    },
                };
                if (node.depth == 0) {
                    nodeCfg.style.fill = '#0055ff';
                    nodeCfg.size = [240, 35]
                    nodeCfg.labelCfg.style.fill = "#ffffff";
                } else if (node.depth == 1 || node.depth == 3) {
                    nodeCfg.size = [80, 35]
                } else if (node.depth == 4) {
                    nodeCfg.size = [280, 35]
                    if (node.percent && node.percent.indexOf('%') > -1) {
                        nodeCfg.label = node.name + "   " + node.percent;
                    }
                } else {
                    nodeCfg.size = [250, 35]
                }
                return nodeCfg
            });
            //给线加上投资比例
            treeGraph.edge((edge) => {
                var edgeCfg = {
                    style: {
                        endArrow: true,
                    }
                }
                var targetNodeId = edge.target;
                var targetNode = treeGraph.findById(targetNodeId);
                if (targetNode._cfg.model.depth == 1 || targetNode._cfg.model.depth == 2) {
                    edgeCfg.style.endArrow = {
                        path: G6.Arrow.vee(6, 6, 0), // 使用内置箭头路径函数，参数为箭头的 宽度、长度、偏移量（默认为 0，与 d 对应）
                        d: 0,
                        fill: "#52b1c2"
                    }
                } else if (targetNode._cfg.model.depth >= 3) {
                    edgeCfg.style.endArrow = {
                        path: G6.Arrow.vee(6, 6, 0), // 使用内置箭头路径函数，参数为箭头的 宽度、长度、偏移量（默认为 0，与 d 对应）
                        d: 0,
                        fill: "#52b1c2"
                    }
                }
                return edgeCfg;
            });
            treeGraph.data(data)
            treeGraph.render()
            treeGraph.fitCenter()

            // treeGraph.fitView()

            treeGraph.on('node:click', (evt) => {
                var item = evt.item;
                var nodeId = item._cfg.model.id;
                //聚焦当前点击
                treeGraph.focusItem(nodeId,true,{
                    easing: 'easeCubic',
                    duration: 400,
                })
            })

           
        },

        chartFullScreen(){
            this.showFullScreen = true;
            this.$nextTick(()=>{
                var width = document.getElementById('out-full').offsetWidth;
                var height = document.getElementById('out-full').offsetHeight;
                console.log(width);
                this.chartRender(this.relationData,'out-full')
            })
        }
    }
}
</script>

<style scoped lang="less">


#outer-invest {
    position: relative;
    height: 450px;

    .btn-full-screen {
        position: absolute;
        right: 0;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    /deep/ .el-dialog__body {
        height: calc(100% - 54px);
        padding: 15px;
        box-sizing: border-box;
        display: flex;
    }
    #out-full {
        flex: 1;
    }
    /* 提示框的样式 */

    /deep/ .g6-tooltip {
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        font-size: 12px;
        color: #545454;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 10px 8px;
        box-shadow: rgb(174, 174, 174) 0px 0px 10px;
    }
}

</style>