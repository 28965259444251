<template>
    <div id="relation">
        <img src="@/assets/img/full-screen.png" class="btn-full-screen" @click="chartFullScreen()">
        <el-dialog title="投资关系" :visible.sync='showFullScreen' fullscreen>
            <div id="full" v-if="showFullScreen" ></div>
        </el-dialog>
    </div>
</template>

<script>
//引入antv g6
import G6 from '@antv/g6';
import {getLocalStore} from '@/util/store'
// const Minimap = require('@antv/g6/build/minimap');
const Plugin = require('@antv/g6-plugin');
// const Minimap = require('@antv/g6-plugin/lib/minimap');

var Minimap = Plugin.Minimap;
export default {
    props: {
        chartData: {},  //父组件传递的关系分析图数据
    },
    data() {
        return {
            relationData: {},
            showFullScreen: false
        }
    },
    mounted() {
        
        this.relationData = JSON.parse(JSON.stringify(this.chartData))
        this.chartRender(this.relationData,'relation');
        //this.chartInit(data);
    },
    methods: {
        chartRender(data,id) {
            //获取元素宽 高
            var dom = document.getElementById(id);
            var width = dom.offsetWidth;
            var height = dom.offsetHeight;
            // var minimap = new Minimap({
            //     size: [window.innerWidth / 4, window.innerHeight / 4]
            // });
            // console.log(minimap)
            //场景图谱实例
            const treeGraph = new G6.TreeGraph({
                container: id,
                width: width,
                height: height,
                animate: true, // Boolean，切换布局时是否使用动画过度，默认为 false
                animateCfg: {
                    duration: 250, // Number，一次动画的时长
                    easing: 'linearEasing', // String，动画函数
                },
                autoPaint: false,
                //交互模式
                modes: {
                    default: [
                        {
                            type: 'drag-canvas',
                            allowDragOnItem: true,//设置节点、边等均可响应拖拽
                        },
                        'collapse-expand',
                    ],
                },
                //定义布局
                layout: {
                    type: 'mindmap',//类型：脑图
                    direction: 'H',
                    getHeight: () => {
                        return 35;
                    },
                    getWidth: () => {
                        return 250;
                    },
                    getVGap: () => { //水平间距
                        return 10;
                    },
                    getHGap: () => { //垂直间距
                        return 50;
                    },
                },
                // plugins: [minimap],
                //默认节点样式
                defaultNode: {
                    type: 'rect', // 节点类型 矩形
                    //type: 'modelRect',
                    /*preRect:false,
                    logoIcon:{
                        show:false
                    },*/
                    draggable: true,
                    size: [250, 35],
                    anchorPoints: [
                        [0, 0.5],
                        [1, 0.5],
                    ],
                    label: "",
                    labelCfg: {
                        position: 'center',
                        //offset:10,
                        style: {
                            fill: '#333',
                        }
                    },
                },
                //默认线的样式
                defaultEdge: {
                    type: 'cubic-horizontal',
                    //type: 'polyline', // 节点类型 矩形
                    //type:'flowLine',
                    style: {
                        stroke: '#8dd3d3',
                        //offset: 15,
                    }
                }
            });
            //节点的文本和样式根据级别进行重新赋值
            treeGraph.node((node) => {
                //console.log(node)
                var nodeCfg = {
                    type: 'rect',
                    size: '',
                    style: {
                        fill: '#ffffff',
                    },
                    label: node.name,
                    labelCfg: {
                        style: {
                            fill: '#333',
                        }
                    },
                };
                if (node.depth == 0) {
                    nodeCfg.style.fill = '#0055ff';
                    nodeCfg.size = [250, 35]
                    nodeCfg.labelCfg.style.fill = "#ffffff";
                } else if (node.depth == 1 || node.depth == 3) {
                    nodeCfg.size = [80, 35]
                } else if (node.depth == 4) {
                    nodeCfg.size = [280, 35]
                    if (node.percent && node.percent.indexOf('%') > -1) {
                        nodeCfg.label = node.name + "   " + node.percent;
                    }
                } else {
                    nodeCfg.size = [250, 35]
                }
                return nodeCfg
            });
            //给线加上投资比例
            treeGraph.edge((edge) => {
                var edgeCfg = {
                    style: {
                        endArrow: false,
                        startArrow: false
                    }
                }
                var targetNodeId = edge.target;
                var targetNode = treeGraph.findById(targetNodeId);
                //console.log(targetNode)
                //console.log(targetNode._cfg.model.depth)
                if (targetNode._cfg.model.depth == 1 || targetNode._cfg.model.depth == 2) {
                    edgeCfg.style.startArrow = {
                        path: G6.Arrow.vee(6, 6, 0), // 使用内置箭头路径函数，参数为箭头的 宽度、长度、偏移量（默认为 0，与 d 对应）
                        d: 0,
                        fill: "#52b1c2"
                    }
                } else if (targetNode._cfg.model.depth >= 3) {
                    edgeCfg.style.endArrow = {
                        path: G6.Arrow.vee(6, 6, 0), // 使用内置箭头路径函数，参数为箭头的 宽度、长度、偏移量（默认为 0，与 d 对应）
                        d: 0,
                        fill: "#52b1c2"
                    }
                }
                return edgeCfg;
            });
            treeGraph.data(data)
            treeGraph.render()
            treeGraph.fitCenter()
            // treeGraph.fitView()
            //fitView()

            treeGraph.on('node:click', (evt) => {
                var item = evt.item;
                var nodeId = item._cfg.model.id;
                var graphId = item._cfg.model.key;
                //聚焦当前点击
                treeGraph.focusItem(nodeId,true,{
                    easing: 'easeCubic',
                    duration: 400,
                })
                if (item._cfg.model.children && item._cfg.model.children.length == 0 && item._cfg.model.depth == 2) {
                    this.axios({
                        method: "get", url: '/companyRelation/investOther',
                        headers:{
                            'Authorization':'Bearer '+getLocalStore('token')
                        },
                        params: {
                            graphId: graphId
                        },
                    }).then((res) => {
                        var childdata = res.data.data.data;
                        treeGraph.updateChildren(childdata, nodeId)
                        // treeGraph.fitView()
                        
                        //填充到初始data中
                        var rawData = this.relationData.children;
                        for (let i = 0; i < rawData.length; i++) {
                            for (let j = 0; j < rawData[i].children.length; j++) {
                                if (rawData[i].children[j].id == nodeId){
                                    rawData[i].children[j].children = childdata;
                                }
                            }
                        }
                    })
                }else if (item._cfg.model.depth == 4){
                    this.$message({
                        showClose: true,
                        message: '已查询到最末级',
                        type: 'warning'
                    });
                }
            })
        },

        chartFullScreen(){
            this.showFullScreen = true;
            this.$nextTick(()=>{
                var width = document.getElementById('full').offsetWidth;
                var height = document.getElementById('full').offsetHeight;
                this.chartRender(this.relationData,'full')
            })
        }
    }
}
</script>

<style scoped lang="less">


#relation {
    position: relative;
    height: 450px;

    .btn-full-screen {
        position: absolute;
        right: 0;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    /deep/ .el-dialog__body {
        height: calc(100% - 54px);
        padding: 15px;
        box-sizing: border-box;
        display: flex;
    }
    #full {
        flex: 1;
    }
    /* 提示框的样式 */

    /deep/ .g6-tooltip {
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        font-size: 12px;
        color: #545454;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 10px 8px;
        box-shadow: rgb(174, 174, 174) 0px 0px 10px;
    }
}

</style>
