<template>
    <div>
        <el-table
            border
            :data="listData"
            height="450"
            stripe
            :header-cell-style="{background:'#F6F6F6',color:'#333333'}"
            style="width: 100%">
            <el-table-column
                type="index"
                label="序号"
                align="center"
                width="50">
            </el-table-column>
            <el-table-column
                prop="companyName"
                label="被投资单位">
            </el-table-column>
            <el-table-column
                prop="otherCompanyName"
                width="280"
                label="投资人">
            </el-table-column>
            <el-table-column
                prop="relationshipType"
                width="120"
                label="关系">
            </el-table-column>
            <el-table-column
                prop="description"
                :show-overflow-tooltip="true"
                label="详情">
            </el-table-column>
        </el-table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                background
                layout="prev,pager,next,total,jumper"
                :total=total
                :current-page=pageNum>
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {getStore,setStore,getLocalStore} from "@/util/store";
export default {
    props: {
        tableData:Array
    },
    data() {
        return {
            listData: [],
            pageNum: 1,
            total: 0,
        }
    },
    watch:{
        tableData(){
            let arr =[].concat(this.tableData);
            this.listData = arr.splice((this.pageNum-1)*10,10);
            this.total = this.tableData.length;
        }
       
    },
    mounted() {
        let arr =[].concat(this.tableData);
        this.listData = arr.splice((this.pageNum-1)*10,10);
        this.total = this.tableData.length;
    },
    methods: {
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                data: {
                    pageName:'building',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
         //更改页码
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getReport(2,'investment',val)
            let arr =[].concat(this.tableData);
            this.listData = arr.splice((this.pageNum-1)*10,10);
        },
  
    }
}
</script>

<style scoped>

</style>