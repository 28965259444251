import { render, staticRenderFns } from "./newsOpinion.vue?vue&type=template&id=bd36a6bc&scoped=true&"
import script from "./newsOpinion.vue?vue&type=script&lang=js&"
export * from "./newsOpinion.vue?vue&type=script&lang=js&"
import style0 from "./newsOpinion.vue?vue&type=style&index=0&id=bd36a6bc&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd36a6bc",
  null
  
)

export default component.exports