<template>
    <div>
        <div id="cooperation-invest"></div>
    </div>
</template>

<script>
import G6 from '@antv/g6';//引入antv g6
export default {
    props: {
        chartdata: {},  //父组件传递的关系分析图数据
    },
    mounted() {
        this.chartInit(this.chartdata);
    },
    methods: {
        chartInit(data) {
            //场景图谱实例
            const treeGraph = new G6.TreeGraph({
                container: 'cooperation-invest',
                width: 1200,
                height: 450,
                animate: true, // Boolean，切换布局时是否使用动画过度，默认为 false
                animateCfg: {
                    duration: 200, // Number，一次动画的时长
                    easing: 'linearEasing', // String，动画函数
                },
                //交互模式
                modes: {
                    default: [
                        {
                            type: 'collapse-expand',
                            onChange: function onChange(item, collapsed) {
                                const data = item.getModel();//获取模型
                                data.collapsed = collapsed;
                                return true;
                            },
                        },
                        /*{
                            type: 'tooltip', // 提示框
                            formatText(model) {
                                // 提示框文本内容
                                const text = '公司名称: ' + model.name;
                                return text;
                            },
                        },*/
                        {
                            type:'drag-canvas',
                            allowDragOnItem:true,//设置节点、边等均可响应拖拽
                        },
                        {
                            type: 'zoom-canvas',
                            sensitivity: 1
                        },
                    ],
                },
                //定义布局
                layout: {
                    type: 'mindmap',//类型：脑图
                    direction: 'LR',
                    getHeight: () => {
                        return 60;
                    },
                    getWidth: () => {
                        return 200;
                    },
                    getVGap: () => { //水平间距
                        return 5;
                    },
                    getHGap: () => { //垂直间距
                        return 20;
                    },
                },
                //默认节点样式
                defaultNode: {
                    type: 'rect', // 节点类型 矩形
                    //type: 'modelRect',
                    /*preRect:false,
                    logoIcon:{
                        show:false
                    },*/
                    size: [240, 35],
                    anchorPoints: [
                        [0, 0.5],
                        [1, 0.5],
                    ],
                    label: "",
                    labelCfg: {
                        position: 'center',
                        //offset:10,
                        style: {
                            fill: '#333',
                        }
                    },
                },
                //默认线的样式
                defaultEdge: {
                    type: 'cubic-horizontal',
                    //type: 'polyline', // 节点类型 矩形
                    style: {
                        stroke: '#8dd3d3',
                        //offset: 15,
                        endArrow : {
                            path: G6.Arrow.vee(6, 6, 0), // 使用内置箭头路径函数，参数为箭头的 宽度、长度、偏移量（默认为 0，与 d 对应）
                            d: 0,
                            fill: "#52b1c2"
                        }
                    }
                }
            });
            //节点的文本和样式根据级别进行重新赋值
            treeGraph.node((node) => {
                //console.log(node)
                var nodeCfg = {
                    type: 'rect',
                    size: '',
                    style: {
                        fill: '#ffffff',
                        cursor: ''
                    },
                    label: node.name,
                    labelCfg: {
                        style: {
                            fill: '#333',
                        }
                    },
                };
                if (node.depth == 0) {
                    nodeCfg.style.fill = '#0055ff';
                    nodeCfg.size = [240, 35]
                    nodeCfg.labelCfg.style.fill = "#ffffff";
                } else if (node.depth == 1 || node.depth == 3) {
                    nodeCfg.size = [80, 35]
                } else if (node.depth == 4 ) {
                    nodeCfg.size = [280, 35]
                    if (node.percent.indexOf('%') > -1){
                        nodeCfg.label = node.name + "   " + node.percent;
                    }
                }else {
                    nodeCfg.size = [250, 35]
                }
                if (node.children) {
                    nodeCfg.style.cursor = "pointer";
                }
                return nodeCfg
            });
            treeGraph.data(data)
            treeGraph.render()
            treeGraph.fitView()

        }
    }
}
</script>

<style scoped lang="less">

.nullrelation {
    width: 250px;
    height: 156px;
    margin: 0 auto;
    background: url('../../../assets/img/nullRelation.png') no-repeat;
}

    /* 提示框的样式 */

    /deep/ .g6-tooltip {
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        font-size: 12px;
        color: #545454;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 10px 8px;
        box-shadow: rgb(174, 174, 174) 0px 0px 10px;
    }


</style>